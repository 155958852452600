
const translations = {

    // CATEGORIES
    "red voznje - info na": {"sr": "Red vožnje - Info na", "en": "Timetable - Info on"},
    "kontakt za pomoc": {"sr": "Kontakt za pomoć", "en": "Help contact"},
    "kontakt forma": {"sr": "Kontakt forma", "en": "Contact form"},
    "mapa sajta": {"sr": "Mapa sajta", "en": "Sitemap"},
    "vazni linkovi": {"sr": "Važni linkovi", "en": "Important links"},
    "adresa": {"sr": "Adresa", "en": "Address"},
    "footer logo desc": {"sr": "Sanžaktrans doo je član međunarodne Kavim grupacije", "en": "Sanžaktrans doo is a member of the international\nKavim group"},

    "procitajte vise": {"sr": "PROČITAJTE VIŠE", "en": "READ MORE"},
    "sve vesti": {"sr": "Sve vesti", "en": "Read all"},
    "telefoni": {"sr": "Telefoni", "en": "Phones"},

    "contact form desc": {
        "sr": "Za sva pitanja i informacije možete nas kontaktirati putem kontakt forme",
        "en": "For all questions and information, you can contact us via the contact form"
    },

    "centrala": {"sr": "Centrala", "en": "Central"},
    "informacije": {"sr": "Informacije", "en": "Info"},
    "biletarnica": {"sr": "Biletarnica", "en": "Ticket office"},
    "tehnicka radionica": {"sr": "Tehnička radionica", "en": "Technical workshop"},
    "tehnicki pregled": {"sr": "Tehnički pregled", "en": "Technical inspection"},

    // FORNA
    "cilj prijave": {"sr": "Cilj prijave", "en": "The purpose of the application"},
    "ucenje": {"sr": "Učenje", "en": "Learning"},
    "posao": {"sr": "Posao", "en": "Job"},
    "morate prihvatiti uslove": {"sr": "Morate prihvatiti uslove.", "en": "You must accept the terms."},
    "job form desc": {
        "sr": "Obavešten sam o svrsi prikupljanja podataka, u smislu čl. 15. Zakona o zaštiti podataka o ličnosti i mojim pravima iz ovog Zakona, i ujedno sam saglasan da se moji sledeći podaci: ime i prezime, adresa prebivališta odnosno boravišta, obrazovanje, podaci za kontakt, obrađuju od strane firme Sanžaktrans doo.",
        "en": "I have been informed about the purpose of data collection, in the sense of Art. 15 of the Law on the Protection of Personal Data and my rights from this Law, and at the same time I agree that my following data: name and surname, residential address, education, contact information, are processed by the company Sanžaktrans doo."
    },
    "autobuska stanica informacije": {"sr": "Autobuska stanica informacije", "en": "Bust station iformations"},
}
export default translations;