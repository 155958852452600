import React, {Suspense} from "react";
import {widgets, pages, boot, register} from "../compiled";
import context from "./context";
import ApolloWrapper from "../apollo/widgets/ApolloWrapper";
import Router from "./Router";

function App() {

    context.widget = (path, params = false) => {
        if (widgets[path] || pages[path]) {

        } else {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('MISSING: ', `Ne postoji widget: ${path}`)
            }
            return null;
        }
        let Element = null;
        let node = null;
        if(widgets[path]?.import)    {
            node = widgets[path];
            Element = widgets[path].import;
        } else if(widgets[path]?.lazy)  {
            node = widgets[path];
            Element = widgets[path].lazy;
        } else if(pages[path]?.lazy)  {
            node = pages[path];
            Element = pages[path].lazy;
        }
        else {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('MISSING: ', `Ne postoji widget: ${path}`)
            }
            return null;
        }
        const arr = path.split(".");
        if (arr[1][0] === arr[1][0].toUpperCase()) {
            return <Suspense fallback={<div className={node.fallback || ""} />}>
                <Element {...params} />
            </Suspense>
        }
        if (params !== false) {
            return node.element(params);
        }
        return node.element;
    }
    context.debugger = (text) => {
        console.log("DEBUGGER", text)
    }

    const checkIsMobile = () => {
        let width;
        if (window.outerHeight) width = window.outerWidth;
        else width = document.body.clientWidth;
        return width < 768;
    }
    context.isMobile = checkIsMobile();

    context.debugger("App");

    return <>
        {boot.map((Element, key) => {
            return <Element key={key} />
        })}

        <ApolloWrapper>
            {register.map((Element, key) => {
                return <Element key={key} />
            })}
            <Router />
        </ApolloWrapper>
    </>
}

export default App;
